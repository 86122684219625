import { createStore } from 'zustand/vanilla';
import create from 'vue-zustand';

const fundingDefaultData = {
  fundSeq: '',
  fundName: '',
  fundPayType: '',
  fundPrice: 0,
  fundPriceType: '',
  fundQuantity: 0,
  fundDueDate: ''
};

const state = {
  isFundingDo: false,
  // bm가입 페이지 타입 설정
  // info = 서류안내 , apply = 신청  check = 설명사항 확인서  end = 신청완료 (default: info)
  pageType: {
    list: ['info', 'apply', 'end'],
    select: 'info'
  },
  userInfo: {},
  fundUserSeq: null,
  fundingData: {
    ...fundingDefaultData,
    invalidFocus: '',
    essential: ['fundSeq', 'fundPayType', 'fundQuantity', 'fundDueDate']
  },
  fundList: [],
  checkList: [],
  checked: '',
  digisign: {
    signKey: '',
    show: false
  }
};

const FundingState = (set, get) => ({
  ...state,
  setDigiSignData(key, value) {
    set((state) => {
      return {
        digisign: {
          ...state.digisign,
          [key]: value
        }
      };
    });
  },
  setFundingData(value) {
    set((state) => {
      return {
        fundingData: {
          ...state.fundingData,
          ...value
        }
      };
    });
  },
  setInvalidFocus(value) {
    set((state) => {
      return {
        fundingData: {
          ...state.fundingData,
          invalidFocus: value
        }
      };
    });
  },
  setModalControl(key, value) {
    set((state) => {
      return {
        loginModal: {
          ...state.loginModal,
          [key]: value
        }
      };
    });
  },
  setPage(type) {
    set((state) => {
      return {
        pageType: {
          ...state.pageType,
          select: type
        }
      };
    });
  },
  setCommonData(key, value) {
    set(() => {
      return {
        [key]: value
      };
    });
  }
});

const FundingStore = create(createStore(FundingState));

export default FundingStore;
