<template>
  <div :class="tStyle.modalWrap">
    <div
      :class="tStyle.modalInner"
      :style="{ width: `${props.width}px`, padding: `${props.padding}` }"
    >
      <slot name="modalContent"></slot>
      <div :class="tStyle.btnWrap">
        <slot name="addBtn"></slot>
        <button
          @click="emit('onClose')"
          :class="[
            tStyle.closeBtn,
            html ? 'text-[14px]' : 'mobile:text-[1.4rem] '
          ]"
        >
          {{ btnText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const emit = defineEmits(['onClose']);
const props = defineProps({
  btnText: String,
  width: Number,
  padding: String,
  html: Boolean
});

const tStyle = ref({
  modalWrap:
    'w-screen h-screen fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-40 z-[100] mobile:px-[24px] mobile:py-[70px]',
  modalInner: `rounded-[16px] bg-white max-h-[90vh] flex flex-col overflow-y-auto`,
  btnWrap:
    'flex items-center justify-center pc:gap-x-[12px] mobile:gap-x-[0.8rem] text-[18px] mobile:text-[1.6rem] font-medium tracking-[-0.36px] mobile:items-stretch mobile:px-[1.5rem] px-[24px]',
  closeBtn:
    'text-[#999] rounded-[4px] py-[9px] w-[132px] border border-[#CFCFCF] mobile:basis-1/2'
});
</script>

<style scoped>
/* 모달 스타일링을 여기에 추가하세요 */
</style>
