import CommonStore from './commonStore';
import LoginStore from '@/feature/login/stores/LoginStore';
import MainStore from '@/feature/main/stores/MainStore';
import JoinStore from '@/feature/join/stores/JoinStore';
import FundingStore from '@/feature/funding/stores/FundingStore';
import FundStore from '@/feature/fund/stores/FundStore';
import InquiryStore from '@/feature/inquiry/stores/InquiryStore';
import EventStore from '@/feature/event/stores/EventStore';
import NoticeStore from '@/feature/notice/stores/NoticeStore';
import DocsStore from '@/feature/docs/stores/DocsStore';
import MyPageStore from '@/feature/mypage/stores/MyPageStore';
import StopStore from '@/feature/stop/stores/StopStore';
import OverStore from '@/feature/over/stores/OverStore';

export const rootStore = {
  commonStore: CommonStore,
  loginStore: LoginStore,
  mainStore: MainStore,
  joinStore: JoinStore,
  fundingStore: FundingStore,
  fundStore: FundStore,
  inquiryStore: InquiryStore,
  eventStore: EventStore,
  noticeStore: NoticeStore,
  docsStore: DocsStore,
  myPageStore: MyPageStore,
  stopStore: StopStore,
  overStore: OverStore
};
