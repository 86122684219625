import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index.js';
import './styles/app.css';
import VueDOMPurifyHTML from 'vue-dompurify-html';

// Prototype Import
import './utils/prototype/date.prototype.js';

createApp(App).use(VueDOMPurifyHTML).use(router).mount('#app');
