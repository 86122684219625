import { createStore } from 'zustand/vanilla';
import create from 'vue-zustand';

const joinAgreeDataType = {
  agreeSeq: 0,
  agreeType: '',
  agreeTitle: '',
  agreeDesc: ''
};

const state = {
  loginInputData: {
    id: '',
    pw: ''
  },
  loginModal: {
    tabIdx: 0,
    show: false
  },
  joinModal: {
    // ready: 약관 동의 전  , agreed: 약관동의 완료 , complete: 가입완료
    state: 'ready',
    show: false,
    // 아이디 중복체크 상태 및 얼럿 노출
    // success: 사용 가능 , fail: 이미 사용중
    idCheckState: '',
    idCheckAlert: false,
    joinResultState: '',
    joinResultAlert: false
  },
  joinInputData: {
    id: '',
    pw: '',
    pwConfirm: '',
    email: ''
  },
  errorAlertData: {
    title: '',
    body: ''
  },
  // 회원가입 약관조회 api 데이터
  joinAgreeData: joinAgreeDataType,
  // 아이디 , 비밀번호 변경 모달 데이터
  findInputData: {
    id: '',
    email: ''
  }
};

const LoginState = (set, get) => ({
  ...state,
  setModalControl(key, value) {
    set((state) => {
      return {
        loginModal: {
          ...state.loginModal,
          [key]: value
        }
      };
    });
  },
  setJoinModal(key, value) {
    set((state) => {
      return {
        joinModal: {
          ...state.joinModal,
          [key]: value
        }
      };
    });
  },
  handleInputId(value) {
    set((state) => {
      return {
        loginInputData: {
          ...state.loginInputData,
          id: value
        }
      };
    });
  },
  resetJoinModal() {
    set((state) => {
      return {
        joinInputData: {
          id: '',
          pw: '',
          pwConfirm: '',
          email: ''
        },
        joinModal: {
          state: 'ready',
          show: false,
          idCheckState: '',
          idCheckAlert: false,
          joinResultState: '',
          joinResultAlert: false
        }
      };
    });
  },
  resetLoginInput() {
    set((state) => {
      return {
        loginInputData: {
          id: '',
          pw: ''
        }
      };
    });
  },
  setErrorAlertData(title, body) {
    set((state) => {
      return {
        errorAlertData: {
          title: title,
          body: body
        }
      };
    });
  },
  setCommonData(key, value) {
    set(() => {
      return {
        [key]: value
      };
    });
  },
  resetJoinModal() {
    set((state) => {
      return {
        joinInputData: {
          id: '',
          pw: '',
          pwConfirm: '',
          email: ''
        },
        joinModal: {
          state: 'ready',
          show: false,
          idCheckState: '',
          idCheckAlert: false,
          joinResultState: '',
          joinResultAlert: false
        }
      };
    });
  }
});

const LoginStore = create(createStore(LoginState));

export default LoginStore;
