<template>
  <div :class="tStyle.sideWrap">
    <div :class="tStyle.sideTop">
      <div :class="tStyle.userWrap">
        <span :class="tStyle.code" v-if="CommonStore.userInfo.userCate !== ''"
          >[{{
            CommonStore.userInfo.userCate === 'U'
              ? message.join.joinType.typeList[0].title[language]
              : CommonStore.userInfo.userCate === 'C'
              ? message.join.joinType.typeList[1].title[language]
              : CommonStore.userInfo.userCate === 'B'
              ? message.join.joinType.typeList[2].title[language]
              : ''
          }}]</span
        >
        <span v-if="CommonStore.userInfo.lastName"
          >{{ CommonStore.userInfo.lastName }}{{ CommonStore.userInfo.firstName
          }}{{ message.login.sir[language] }},
        </span>
        {{ message.login.hello2[language] }} {{ ':)' }}
      </div>
      <img
        src="@/assets/close_btn.png"
        alt=""
        :class="tStyle.closeBtn"
        @click="sideMenu(false)"
      />
    </div>
    <div :class="tStyle.homeNoticeWrap">
      <div
        :class="tStyle.eachWrap"
        @click="
          () => {
            router.push('/');
            sideMenu(false);
          }
        "
      >
        <img
          src="@/assets/side_home.png"
          alt=""
          :class="tStyle.homeNoticeImg"
        />
        <p>{{ message.myPage.myPage.home[language] }}</p>
      </div>
      <div
        :class="tStyle.eachWrap"
        @click="
          () => {
            router.push('/notice');
            sideMenu(false);
          }
        "
      >
        <img
          src="@/assets/side_notice.png"
          alt=""
          :class="tStyle.homeNoticeImg"
        />
        <p>{{ message.notice.title[language] }}</p>
      </div>
    </div>
    <div :class="tStyle.goPageWrap">
      <p :class="tStyle.goPage" @click="goMyPage">
        {{ message.myPage.myPage.title[language] }}
      </p>
      <p :class="tStyle.goPage" @click="logout">
        {{ message.login.logOut[language] }}
      </p>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import message from '@/lib/message.json';

import { rootStore } from '@/store/rootStore';

const CommonStore = reactive(rootStore?.commonStore());
const MainStore = reactive(rootStore?.mainStore());

const language = ref(CommonStore.language);
const router = useRouter();
const route = useRoute();

const sideMenu = (value) => {
  MainStore.setSideMenu('show', value);
};

const logout = () => {
  CommonStore.setLogOut();
  sideMenu(false);
  router.push('/login');
};

const goMyPage = () => {
  if (route.path == '/mypage') {
    MainStore.mobileSideMenu.show = false;
  } else {
    MainStore.pwModal.show = true;
  }
};

const tStyle = ref({
  sideWrap: 'fixed h-screen w-[80%] bg-white top-0 left-0 z-[30]',
  sideTop:
    'flex items-center justify-between bg-[#ECF2E1] mobile:p-[2.25rem] relative',
  closeBtn:
    'mobile:w-[2.24rem] absolute mobile:top-[1.45rem] mobile:right-[1.45rem]',
  userWrap: 'text-[#4F4A44] font-medium mobile:text-[1.66rem]',
  code: 'font-bold',
  homeNoticeWrap:
    'grid grid-cols-2 text-[#646464] font-medium mobile:text-[1.3rem]',
  eachWrap:
    ' flex flex-col items-center mobile:py-[2.25rem] mobile:gap-y-[0.4rem]',
  homeNoticeImg: 'mobile:w-[3rem]',
  goPage:
    'mobile:py-[1.55rem] mobile:px-[2.25rem] mobile:text-[1.57rem] text-[#4f4a44] font-medium border-t border-[#D1DEAF]'
});
</script>
<style scoped></style>
