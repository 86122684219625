<template>
  <div class="flex flex-col w-full h-full min-h-screen whitespace-pre-line">
    <router-view
      class="w-full pc:mt-16 mobile:mt-[5rem] pb-[104px] pc:px-[24px]"
    />
    <MenuBar />
    <BaseAlert
      v-if="CommonStore.errState === true"
      :title="CommonStore.errMsg.error"
      :body="CommonStore.errMsg.message"
      :btnText="message.common.modal.confirm[language]"
      @onClose="handleAlertClose"
    />
    <BaseModal
      :btnText="message.common.modal.close[language]"
      @onClose="CommonStore.setCommonData('timeoutModal', false)"
      :width="400"
      v-if="CommonStore.timeoutModal"
      :padding="CommonStore.isMobile ? '4rem 3.5rem' : '32px 64px'"
    >
      <template v-slot:modalContent>
        <div class="text-center">
          <div :class="tStyle.modalTitle">
            {{ message.main.timeout.title[language] }}
          </div>
          <div :class="tStyle.inter">
            {{ CommonStore.loginTimeCount }}
            {{ message.main.timeout.sec[language] }}
          </div>
          <div :class="tStyle.subTitle">
            {{ renderText(message.main.timeout.body[language]) }}
          </div>
        </div>
      </template>
      <template v-slot:addBtn>
        <button @click="keeping" :class="tStyle.findBtn">
          {{ message.main.timeout.continue[language] }}
        </button>
      </template>
    </BaseModal>
  </div>
</template>
<script setup>
import {
  onMounted,
  reactive,
  computed,
  watchEffect,
  watch,
  onUpdated,
  ref
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { renderText } from '@/utils/renderText';

import MenuBar from '@/feature/common/components/MenuBar.vue';
import Request from '@/utils/api/Request';
import getLog from './utils/getLog';
import message from '@/lib/message.json';

import BaseAlert from './feature/common/components/BaseAlert.vue';
import BaseModal from './feature/common/components/BaseModal.vue';

import { rootStore } from '@/store/rootStore';

const CommonStore = reactive(rootStore?.commonStore());
const language = ref(rootStore?.commonStore().language);

const route = useRoute();
const router = useRouter();

let LOCAL_TOKEN = localStorage.getItem('ows');

const handleAlertClose = () => {
  CommonStore.errState = false;
};

const keeping = () => {
  CommonStore.loginTimeCount = 1800;
  CommonStore.setCommonData('timeoutModal', false);
};

const checkMobile = () => {
  const getWidth = window.innerWidth < 768;
  CommonStore.setMobile(getWidth);
};

const checkLang = () => {
  const local = localStorage.getItem('kjlang');
  if (local !== null) {
    CommonStore.language = local;
  }
};

const checkRefresh = async () => {
  let result = await Request('/owuser/api/com/v1/request_main_state', 'post');

  if (!result) return;

  const { API_CODE, USER } = result;
  getLog('refresh api', result);

  if (API_CODE.status === '0000') {
    CommonStore.setIsLogin(true);
    CommonStore.setUserInfo(USER);
  }
};

onMounted(() => {
  checkLang();
  window.scrollTo(0, 0);
  checkMobile();
  if (LOCAL_TOKEN) {
    checkRefresh();
  }
});

const logout = () => {
  CommonStore.setLogOut();
  router.push('/login');
};

watch(
  () => CommonStore.loginTimeCount,
  () => {
    if (CommonStore.loginTimeCount === 30) {
      CommonStore.setCommonData('timeoutModal', true);
    }
    if (CommonStore.loginTimeCount === 0) {
      logout();
    }
  }
);

watch(
  () => CommonStore.isLogin,
  (a, b) => {
    if (b && !a) {
      CommonStore.setCommonData('loginTimeCount', 1800);
      CommonStore.setCommonData('timeoutModal', false);
      clearInterval(CommonStore.interval);
    } else {
      // 로그인시 리프레시 한번 실행
      checkRefresh();

      const interval = setInterval(() => {
        CommonStore.loginTimeCount--;
      }, 1000);
      CommonStore.setCommonData('interval', interval);
    }
  }
);

watchEffect(() => {
  CommonStore.currentPath = route.path;
  if (CommonStore.goLoginPage) {
    router.push('/login');
    CommonStore.goLoginPage = false;
  }
  // 접근 불가능한 페이지 접근시 얼럿
  if (CommonStore.inAccess && CommonStore.userInfo?.joinState == 'C') {
    CommonStore.errMsg = {
      error: '',
      message: message.mainPop.inAccess.body[language.value]
    };
    CommonStore.errState = true;
    CommonStore.inAccess = false;
  }

  if (route.path == '/join') {
    router.push('/join/regist');
  }
});

const tStyle = {
  modalTitle:
    'text-[#222] text-[16px] font-semibold tracking-[-0.4px] mobile:text-[1.6rem]',
  inter: 'text-key font-bold pc:text-[32px] mobile:text-[3.2rem] py-[16px]',
  subTitle:
    'text-[#646464] font-medium tracking-[-0.32px] mobile:text-[1.6rem] pc:pb-[16px] mobile:pb-[1.6rem]',
  findBtn:
    'text-[#fff] rounded-[4px] bg-key py-[9px] px-[12px] mobile:text-[1.4rem] w-[132px]'
};
</script>
<style>
body,
html,
#app {
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222;
  background: #ecf2e1;
  font-family: 'Pretendard-Regular';
}

input::-webkit-inner-spin-button {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

input[type='date'] {
  position: relative;
  appearance: none;
}
input[type='date']::before {
  content: attr(place);
  width: 100%;
}

input[type='date']:focus::before,
input[type='date']:valid::before {
  display: none;
}
input[type='date']::-webkit-clear-button,
input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  color: transparent;
  cursor: pointer;
}

@media (max-width: 768px) {
  html {
    font-size: 2.566vw;
  }
}
</style>
