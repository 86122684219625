Date.prototype.yyyymmdd = function (glue) {
  if (glue == null) {
    glue = '';
  }
  var yyyy = this.getFullYear();
  var mm =
    this.getMonth() < 9 ? '0' + (this.getMonth() + 1) : this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate() < 10 ? '0' + this.getDate() : this.getDate();
  return ''.concat(yyyy).concat(glue).concat(mm).concat(glue).concat(dd);
};

Date.prototype.yyyymmddhhmm = function (glue) {
  if (glue == null) {
    glue = '';
  }
  var yyyy = this.getFullYear();
  var mm =
    this.getMonth() < 9 ? '0' + (this.getMonth() + 1) : this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate() < 10 ? '0' + this.getDate() : this.getDate();
  var hh = this.getHours() < 10 ? '0' + this.getHours() : this.getHours();
  var min =
    this.getMinutes() < 10 ? '0' + this.getMinutes() : this.getMinutes();
  return ''
    .concat(yyyy)
    .concat(glue)
    .concat(mm)
    .concat(glue)
    .concat(dd)
    .concat(' ')
    .concat(hh)
    .concat(':')
    .concat(min);
};
Date.prototype.yyyymmddhhmmss = function (glue) {
  if (glue == null) {
    glue = '';
  }
  var yyyy = this.getFullYear();
  var mm =
    this.getMonth() < 9 ? '0' + (this.getMonth() + 1) : this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate() < 10 ? '0' + this.getDate() : this.getDate();
  var hh = this.getHours() < 10 ? '0' + this.getHours() : this.getHours();
  var min =
    this.getMinutes() < 10 ? '0' + this.getMinutes() : this.getMinutes();
  var ss = this.getSeconds() < 10 ? '0' + this.getSeconds() : this.getSeconds();
  return ''
    .concat(yyyy)
    .concat(glue)
    .concat(mm)
    .concat(glue)
    .concat(dd)
    .concat(' ')
    .concat(hh)
    .concat(':')
    .concat(min)
    .concat(':')
    .concat(ss);
};
Date.prototype.yyyymmddhhmmsssss = function (glue) {
  if (glue == null) {
    glue = '';
  }
  var yyyy = this.getFullYear();
  var mm =
    this.getMonth() < 9 ? '0' + (this.getMonth() + 1) : this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate() < 10 ? '0' + this.getDate() : this.getDate();
  var hh = this.getHours() < 10 ? '0' + this.getHours() : this.getHours();
  var min =
    this.getMinutes() < 10 ? '0' + this.getMinutes() : this.getMinutes();
  var ss = this.getSeconds() < 10 ? '0' + this.getSeconds() : this.getSeconds();
  var sss = this.getMilliseconds();
  if (sss < 10) {
    sss = '00' + this.getMilliseconds();
  } else if (sss < 100) {
    sss = '0' + this.getMilliseconds();
  }
  return ''
    .concat(yyyy)
    .concat(glue)
    .concat(mm)
    .concat(glue)
    .concat(dd)
    .concat(' ')
    .concat(hh)
    .concat(':')
    .concat(min)
    .concat(':')
    .concat(ss)
    .concat('.')
    .concat(sss);
};
