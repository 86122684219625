import { createStore } from 'zustand/vanilla';
import create from 'vue-zustand';

const state = {
  eventModal: {
    show: false
  },
  search: {
    type: '',
    keyword: ''
  },
  noticeList: [],
  detailData: {}
};

const NoticeState = (set, get) => ({
  ...state,
  setModalControl(key, value, modalType, data) {
    set((state) => {
      return {
        [modalType]: {
          ...state[modalType],
          [key]: value,
          ...data
        }
      };
    });
  },
  setCommonData(key, value) {
    set((state) => {
      return {
        ...state,
        [key]: value
      };
    });
  },
  setJoinModal(key, value) {
    set((state) => {
      return {
        joinModal: {
          ...state.joinModal,
          [key]: value
        }
      };
    });
  },
  handleInputId(value) {
    set((state) => {
      return {
        loginInputData: {
          ...state.loginInputData,
          id: value
        }
      };
    });
  },
  resetJoinModal() {
    set((state) => {
      return {
        joinInputData: {
          id: '',
          pw: '',
          pwConfirm: '',
          email: ''
        },
        joinModal: {
          state: 'ready',
          show: false,
          idCheckState: '',
          idCheckAlert: false,
          joinResultState: '',
          joinResultAlert: false
        }
      };
    });
  }
});

const NoticeStore = create(createStore(NoticeState));

export default NoticeStore;
