import { createStore } from 'zustand/vanilla';
import create from 'vue-zustand';

const defaultState = {
  refresh: '',
  navList: [
    {
      path: '/funding/info',
      view: 'NewFunding'
    },
    {
      path: '/fund',
      view: 'FundManage'
    },
    {
      path: '/docs/info',
      view: 'OwDocs'
    },
    {
      path: '/event',
      view: 'EventManage'
    },
    {
      path: '/inquiry/reg',
      view: 'InquiryPage'
    }
  ],
  popData: {
    popOpen: false,
    title: '',
    body: '',
    fileUrl: '',
    okBtn: '',
    closeBtn: '',
    closeBtnYn: false
  },
  mobileSideMenu: {
    show: false
  },
  notiList: [],
  fundList: [],
  agentList: [],
  qrModal: {
    show: false
  },
  pwModal: {
    show: false
  },
  fundDetailModal: {
    show: false
  },
  letterModal: {
    show: false
  },
  resignModal: {
    show: false
  },
  selectedFund: null,
  selectAgent: {},
  resignData: {},
  htmlDocsModal: {
    open: false,
    docsList: [],
    nextBtnText: ''
  }
};

const state = { ...defaultState };

const MainState = (set, get) => ({
  ...state,
  setCommonData(key, value) {
    set(() => {
      return {
        [key]: value
      };
    });
  },
  setModalControl(key, value, modalType) {
    set((state) => {
      return {
        [modalType]: {
          ...state[modalType],
          [key]: value
        }
      };
    });
  },
  setJoinModal(key, value) {
    set((state) => {
      return {
        joinModal: {
          ...state.joinModal,
          [key]: value
        }
      };
    });
  },
  handleInputId(value) {
    set((state) => {
      return {
        loginInputData: {
          ...state.loginInputData,
          id: value
        }
      };
    });
  },
  resetJoinModal() {
    set((state) => {
      return {
        joinInputData: {
          id: '',
          pw: '',
          pwConfirm: '',
          email: ''
        },
        joinModal: {
          state: 'ready',
          show: false,
          idCheckState: '',
          idCheckAlert: false,
          joinResultState: '',
          joinResultAlert: false
        }
      };
    });
  },
  setSideMenu(key, value) {
    set((state) => {
      return {
        mobileSideMenu: {
          ...state.mobileSideMenu,
          [key]: value
        }
      };
    });
  },
  setSelectedFund: (fundData) => {
    set((state) => ({
      selectedFund: fundData
    }));
  },
  resetState() {
    set((state) => {
      return {
        ...defaultState
      };
    });
  }
});

const MainStore = create(createStore(MainState));

export default MainStore;
