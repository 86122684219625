import { createStore } from 'zustand/vanilla';
import create from 'vue-zustand';

const userInfoSample = {
  lastDate: '',
  userCate: '',
  gender: '',
  lname: '',
  fname: '',
  furiLname: '',
  furiFname: '',
  enLname: '',
  enFname: '',
  birth: '',
  eraCode: '',
  email: '',
  mobile: '',
  phone: '',
  lineId: '',
  zipCode: '',
  addr: '',
  country: ''
};

const jobInfoSample = {
  lastDate: '',
  jobCate: '',
  jobCateWrite: '',
  jobType: '',
  jobTypeWrite: '',
  placeNm: '',
  furi: '',
  zipCode: '',
  addr: '',
  phone: ''
};

const recvInfoSample = {
  fname: '',
  zipCode: '',
  lname: '',
  addr2: '',
  addr: '',
  lastDate: ''
};

const bankInfoSample = {
  bkOwner: '',
  bkName: '',
  bkArea: '',
  bkCode: '',
  bkNum: '',
  noType: '',
  noTypeTxt: '',
  lastDate: ''
};

const walletInfoSample = {
  walletId: '',
  walletAddr: '',
  qrUrl: '',
  lastDate: ''
};

const idInfoSample = {
  userId: '',
  rgstDate: '',
  lastDate: ''
};

const state = {
  pwChangeModal: {
    type: ''
  },
  userPw: '',
  userInfo: userInfoSample,
  jobInfo: jobInfoSample,
  recvInfo: recvInfoSample,
  bankInfo: bankInfoSample,
  walletInfo: walletInfoSample,
  idInfo: idInfoSample,
  agentList: [],
  jobList: [],
  jobTypeList: [],
  loginLogList: [],
  pwChange: {
    old: '',
    new: '',
    newChk: ''
  }
};

const MyPageState = (set, get) => ({
  ...state,
  setModalControl(key, value, modalType, data) {
    set((state) => {
      return {
        [modalType]: {
          ...state[modalType],
          [key]: value,
          ...data
        }
      };
    });
  },
  setCommonData(key, value) {
    set((state) => {
      return {
        ...state,
        [key]: value
      };
    });
  }
});

const MyPageStore = create(createStore(MyPageState));

export default MyPageStore;
