<template>
  <div :class="tStyle.alertWrap">
    <div :class="tStyle.alertInner">
      <div :class="tStyle.alertTitle">{{ title }}</div>
      <div :class="tStyle.alertBody">{{ body }}</div>
      <div :class="tStyle.btnWrap">
        <slot name="addBtn"></slot>
        <button @click="emit('onClose')" :class="tStyle.closeBtn">
          {{ btnText }}
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';

const emit = defineEmits(['onClose']);

const props = defineProps({
  btnText: String,
  width: Number,
  title: String,
  body: String
});


const tStyle = ref({
  alertWrap:
    'w-screen h-screen fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-40 z-[110] mobile:px-[2.4rem] text-center',
  alertInner: `rounded-[16px] bg-white max-w-[${props.width}px] py-[32px] pc:px-[64px] mobile:px-[3.2rem]`,
  btnWrap:
    'flex items-center justify-center gap-x-[12px] text-[18px] font-medium tracking-[-0.36px]',
  closeBtn: 'text-[#fff] rounded-[4px] py-[9px] w-[132px] bg-key mt-[16px]',
  alertTitle: 'font-bold pc:text-[16px] mobile:text-[1.6rem]',
  alertBody:
    'font-medium mt-[8px] text-[16px] mobile:text-[1.6rem] text-[#424242]'
});
</script>
