import { createStore } from 'zustand/vanilla';
import create from 'vue-zustand';

const inquiryListSample = {
  category: '1',
  title: 'gg',
  body: 'gg'
};

const defaultInput = {
  category: '',
  title: '',
  body: '',
  file1: { name: '' },
  qnaFile: { name: '' }
};

const state = {
  // 문의 탭 타입 설정
  // reg = 문의하기 , ans = 문의답변 (default: reg)
  tapType: {
    list: ['reg', 'ans'],
    select: 'reg'
  },
  inquiryModal: {
    show: false
  },
  inquiryInput: defaultInput,
  inquiryList: [],
  inquiryView: [],
  inquiryDetail: defaultInput,
  inquiryUpdate: {},
  editFile: { name: '' }
};

const InquiryState = (set, get) => ({
  ...state,
  setModalControl(key, value, modalType) {
    set((state) => {
      return {
        [modalType]: {
          ...state[modalType],
          [key]: value
        }
      };
    });
  },
  setTapType(type) {
    set((state) => {
      return {
        tapType: {
          ...state.tapType,
          select: type
        }
      };
    });
  },
  setInquiryUpdate: (data) => {
    set((state) => ({
      inquiryUpdate: {
        ...state.inquiryUpdate,
        ...data
      }
    }));
  },
  setCommonData(key, value) {
    set(() => {
      return {
        [key]: value
      };
    });
  },
  resetInput() {
    set(() => {
      return {
        inquiryInput: {
          category: '',
          title: '',
          body: '',
          file1: { name: '' },
          qnaFile: { name: '' }
        }
      };
    });
  }
});

const InquiryStore = create(createStore(InquiryState));

export default InquiryStore;
