// 줄바꿈처리 사용법

// vue script 내에 import {renderText} from '@/utils/renderText'

// 따로 정의할 필요 없이 templete내에서 사용 가능 
// ex)
// <template>
//   {{ renderText(messages..., commonStore.isMobile) }}
// </template>

// message.json
// pc = \r , mobile = \t

export const renderText = (text, isMobile) => {
  let render = text.replace(isMobile ? /\t/g : /\r/g, '\n');

  return render;
};
