import { createRouter, createWebHistory } from 'vue-router';
import commonStore from '@/store/commonStore';
const { isLogin, userInfo, setInAccess } = commonStore();

const routes = [
  {
    path: '/',
    name: 'MainHome',
    component: () =>
      import(/* webpackChunkName: "main" */ '@/feature/main/views/MainHome.vue')
  },
  /* 로그인 */
  {
    path: '/login',
    name: 'UserLogin',
    component: () =>
      import(
        /* webpackChunkName: "login" */ '@/feature/login/views/UserLogin.vue'
      )
  },
  /* 회원가입 */
  {
    path: '/join/:type',
    name: 'JoinView',
    component: () =>
      import(/* webpackChunkName: "join" */ '@/feature/join/views/JoinView.vue')
  },
  /* 마이페이지 */
  {
    path: '/mypage',
    name: 'MyPage',
    component: () =>
      import(
        /* webpackChunkName: "mypage" */ '@/feature/mypage/views/MyPage.vue'
      )
  },
  /* ow 알림창 기금신청 시작 */
  /* 서류안내 */
  {
    path: '/funding/:type',
    name: 'NewFunding',
    component: () =>
      import(
        /* webpackChunkName: "funding" */ '@/feature/funding/views/NewFunding.vue'
      )
  },
  /* 중도해약 */
  {
    path: '/stop/:type',
    name: 'FundStop',
    component: () =>
      import(
        /* webpackChunkName: "fundstop" */ '@/feature/stop/views/FundStop.vue'
      )
  },
  /* 출자관리 만기 서류 */
  {
    path: '/over/:type',
    name: 'OverFund',
    component: () =>
      import(
        /* webpackChunkName: "fundover" */ '@/feature/over/views/OverFund.vue'
      )
  },
  /* 서류관리 oneworld */
  {
    path: '/docs/:type',
    name: 'OwDocs',
    component: () =>
      import(/* webpackChunkName: "docs" */ '@/feature/docs/views/OwDocs.vue')
  },
  /* 출자관리 */
  {
    path: '/fund',
    name: 'FundManage',
    component: () =>
      import(
        /* webpackChunkName: "fund" */ '@/feature/fund/views/FundManage.vue'
      )
  },
  /* 이벤트관리 */
  {
    path: '/event',
    name: 'EventManage',
    component: () =>
      import(
        /* webpackChunkName: "event" */ '@/feature/event/views/EventManage.vue'
      )
  },
  /* 문의하기 */
  {
    path: '/inquiry/:type',
    name: 'InquiryPage',
    component: () =>
      import(
        /* webpackChunkName: "qna" */ '@/feature/inquiry/views/InquiryPage.vue'
      ),
    props: true
  },
  /* 공지사항 */
  {
    path: '/notice',
    name: 'NoticePage',
    component: () =>
      import(
        /* webpackChunkName: "notice" */ '@/feature/notice/views/NoticePage.vue'
      )
  },
  /* 공지사항상세*/
  {
    path: '/notice/:id',
    name: 'NoticeDetail',
    component: () =>
      import(
        /* webpackChunkName: "notice" */ '@/feature/notice/views/NoticeDetail.vue'
      )
  },
  // 비밀번호 재설정 페이지
  {
    path: '/newpw',
    name: 'ChangePw',
    component: () =>
      import(
        /* webpackChunkName: "newpw" */ '@/feature/changePw/views/ChangePw.vue'
      )
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
