import { createStore } from 'zustand/vanilla';
import create from 'vue-zustand';

const defaultUserInfo = {
  addr: '',
  applySeq: '',
  applyState: '',
  birth: '',
  eraCode: '',
  fileLname1: '',
  filePath: '',
  filePname1: '',
  fileUrl1: '',
  fileUrl2: '',
  fileUrl3: '',
  firstName: '',
  furiFname: '',
  furiLname: '',
  lastName: '',
  mobile: '',
  phone: '',
  rgstDate: '',
  rgstId: '',
  userUuid: '',
  zipCode: '',
  countrySeq: '',
  recvZipCode: '',
  recvAddr: '',
  parentFuriLname: '',
  parentFuriFname: '',
  parentLname: '',
  parentFname: '',
  parentBirth: '',
  parentEraCode: '',
  corpSetUpDate: ''
};

const defaultIdData = [
  {
    fileLname: ''
  },
  {
    fileLname: ''
  },
  {
    fileLname: ''
  },
  {
    fileLname: ''
  },
  {
    fileLname: ''
  },
  {
    fileLname: ''
  },
  {
    fileLname: ''
  },
  {
    fileLname: ''
  },
  {
    fileLname: ''
  },
  {
    fileLname: ''
  }
];

const defaultIdFile = {
  // - idFront : 신분증(앞) / 본인, 대표자
  // - idBack : 신분증(뒤) / 본인, 대표자
  // - idThin : 신분증(두께) / 본인, 대표자
  // - idFace : 얼굴사진과 신분증 / 본인, 대표자
  // - etc : 본인 인증 기타 / 본인, 대표자
  // - pIdFront : 신분증(앞) / 친권자
  // - pIdBack : 신분증(뒤) / 친권자
  // - pIdThin : 신분증(두께) / 친권자
  // - pIdFace : 얼굴사진과 신분증 / 친권자
  // - pEtc : 기타 / 친권자
  // - family : 가족관계증명서 / 친권자
  idFront: { name: '' },
  idBack: { name: '' },
  idThin: { name: '' },
  idFace: { name: '' },
  etc: { name: '' },
  pIdFront: { name: '' },
  pIdBack: { name: '' },
  pIdThin: { name: '' },
  pIdFace: { name: '' },
  family: { name: '' },
  pEtc: { name: '' }
};

const defaultAccData = {
  bkName: '',
  bkArea: '',
  bkCode: '',
  noType: '',
  bkOwner: '',
  bkNum: '',
  accFile: '',
  agree: false,
  signPw: ''
};

const showDocsList = {
  U1001: ['OW0001'],
  U1002: [],
  U1003: ['OW0023'],
  U1004: [],
  U1005: [],
  U1006: []
};

const defaultState = {
  rePaging: false,
  genderList: ['M', 'F'],
  // 서류 타입 설정
  // info = 일반 , bm = BM 서류 (default: user)
  docsType: {
    list: ['info', 'fund'],
    select: 'info'
  },
  userDocsList: [],
  agentDocsList: [],
  memberOutList: [],
  fundDocsList: [],
  selectDocs: {},
  userInfoData: defaultUserInfo,
  addrList: [],
  // address search modal toggle
  addrModalShow: false,
  // address type
  // 회원 = user , 송부처 = send
  addrModalType: '',
  modalDocsCode: '',
  // 신청전 서류 이미지를 노출히야 하는 서류 코드 목록
  docsViewList: ['U1001', 'U1004', 'U1006', 'U1008', 'U1009', 'U1017'],
  idData: defaultIdData,
  inputIdFile: defaultIdFile,
  accountData: defaultAccData,
  expireDate: {
    expire1: '',
    expire2: ''
  },
  accFile: { name: '' },
  countryModal: false,
  showDocs: showDocsList
};

const state = { ...defaultState };

const DocsState = (set, get) => ({
  ...state,
  setModalControl(key, value, modalType, data) {
    set((state) => {
      return {
        [modalType]: {
          ...state[modalType],
          [key]: value,
          ...data
        }
      };
    });
  },
  setDocsType(type) {
    set((state) => {
      return {
        docsType: {
          ...state.docsType,
          select: type
        }
      };
    });
  },
  setCommonData(key, value) {
    set((state) => {
      return {
        ...state,
        [key]: value
      };
    });
  },
  setFileReset() {
    set((state) => {
      return {
        ...state,
        userInfoData: { ...defaultUserInfo },
        idData: defaultIdData,
        inputIdFile: defaultIdFile,
        accountData: { ...this.accountData, defaultAccData }
      };
    });
  },
  rePage() {
    set(() => ({
      rePaging: true
    }));
  },
  resetState() {
    set((state) => {
      return {
        ...defaultState
      };
    });
  }
});

const DocsStore = create(createStore(DocsState));

export default DocsStore;
