import { createStore } from 'zustand/vanilla';
import create from 'vue-zustand';

const userDefaultData = {
  // 법인 후리가나
  corpFuri: '',
  // 법인명
  corpName: '',
  // 후리가나 성
  furiLname: '',
  // 후리가나 이름
  furiFname: '',
  // 성명 성
  lastName: '',
  // 성명 이름
  firstName: '',
  // 법인설립일
  corpSetUpDate: '',
  // 생년월일
  birth: '',
  // 서력, 연호
  eraCode: '',
  // 국적
  ccode: '',
  // 휴대전화
  mobile: '',
  // 일반전화
  phone: '',
  // 우편번호
  zipCode: '',
  // 주소
  addr: '',
  // 송부처 우편번호
  recvZipCode: '',
  // 송부처 주소
  recvAddr: '',
  // 얼굴사진
  faceImgFile: {
    name: ''
  },
  // 친권자 후리가나 성
  parentFuriLname: '',
  // 친권자 후리가나 이름
  parentFuriFname: '',
  // 친권자 성
  parentLname: '',
  // 친권자 이름
  parentFname: '',
  // 친권자 생년월일
  parentBirth: '',
  // 친권자 서력
  parentEraCode: '',
  // 직업 시퀀스
  jobSeq: '',
  // 기타직업 직접입력
  cateWrite: '',
  // 근무처 후리가나
  furiForJob: '',
  // 근무처 학교명
  placeNmForJob: '',
  // 근무처 우편번호
  zipCodeForJob: '',
  // 근무처 상세주소
  addrForJob: '',
  // 근무처 연락처
  phoneForJob: '',
  // 친권자 얼굴 사진
  pfaceImgFile: {
    name: ''
  },
  // 이력사항 전부 증명서
  historyFile: {
    name: ''
  },
  idcardImg: {
    idcard1: { name: '' },
    idcard2: { name: '' },
    idcard3: { name: '' },
    idcard4: { name: '' },
    etc1: { name: '' },
    pidcard1: { name: '' },
    pidcard2: { name: '' },
    pidcard3: { name: '' },
    pidcard4: { name: '' },
    family: { name: '' },
    etc2: { name: '' }
  },
  essential: {
    U: [
      'furiLname',
      'furiFname',
      'lastName',
      'firstName',
      'birth',
      'ccode',
      'mobile',
      'zipCode',
      'addr',
      'jobSeq',
      'furiForJob',
      'placeNmForJob',
      'zipCodeForJob',
      'addrForJob',
      'phoneForJob'
    ],
    C: [
      'furiLname',
      'furiFname',
      'lastName',
      'firstName',
      'birth',
      'ccode',
      'mobile',
      'zipCode',
      'addr',
      'jobSeq',
      'furiForJob',
      'placeNmForJob',
      'zipCodeForJob',
      'addrForJob',
      'phoneForJob',
      'parentLname',
      'parentFname',
      'parentFuriLname',
      'parentFuriFname',
      'parentBirth'
    ],
    B: [
      'corpFuri',
      'corpName',
      'lastName',
      'firstName',
      'corpSetUpDate',
      'mobile',
      'zipCode',
      'addr',
      'historyFile'
    ]
  },
  cardEssential: {
    U: ['idcard1', 'idcard2', 'idcard3', 'idcard4'],
    C: [
      'idcard1',
      'idcard2',
      'idcard3',
      'idcard4',
      'pidcard1',
      'pidcard2',
      'pidcard3',
      'pidcard4'
    ],
    B: ['idcard1', 'idcard2', 'idcard3', 'idcard4']
  }
};

const addUserDefaultData = {
  // 직업시퀀스
  cate: '',
  // 직업명
  cateWrite: '',
  // 근무처 /학교명
  placeNm: '',
  // 근무처 / 학교명 후리가나
  furi: '',
  // 우편번호
  zipCode: '',
  // 주소
  addr: '',
  // 근무처 , 학교 연락처
  phone: ''
};

const mainState = {
  isAdult: true,
  isJoinDo: false,
  selectedButtonIndex: null,
  // 회원가입 페이지 타입 설정
  // secret = 비밀보장 , resist = 회원등록안내 , info = 사전안내 , type = 가입구분 , agree = 동의 , docs = 서류안내 , write = 정보입력 , kyc = kyc등록 , sign = 전자서명 (default = info)
  pageType: {
    list: ['info', 'type', 'agree', 'write', 'kyc', 'sign', 'complete'],
    addList: [
      'regist',
      'secret',
      'summary',
      'type',
      'info',
      'docs',
      'write',
      'check',
      'kyc',
      'sign',
      'complete'
    ],
    navList: ['info', 'docs', 'write', 'check', 'kyc', 'sign'],
    select: 'secret'
  },
  joinInputData: {
    userCate: {
      // U = 일반 , C = 미성년 , B = 법인
      type: ['U', 'B'],
      select: ''
    },
    userData: userDefaultData,
    invalidFocus: ''
  },
  addInputData: addUserDefaultData,
  countryList: [],
  addrList: [],
  kycPageType: {
    list: ['info', 'reg'],
    select: 'info'
  },
  addrModal: {
    show: false,
    type: ''
  },
  dcrModal: {
    show: false
  },
  decSelectModal: {
    show: false
  },
  countryModal: {
    show: false
  },
  signImg: {
    isSigned: false,
    imgFile: {}
  },
  signPw: {
    set: '',
    confirm: ''
  },
  alertModal: {
    state: '',
    show: false,
    title: '',
    body: ''
  },
  corpOwner: [
    {
      furiL: '',
      furiF: '',
      nameL: '',
      nameF: '',
      enL: '',
      enF: '',
      birth: '',
      eraCode: '',
      gender: '',
      ccode: '',
      zipCode: '',
      addr: '',
      gang: '',
      pep: '',
      choice: '',
      relation: '',
      reasonSeq: '',
      idx: '1'
    }
  ],
  // 실질적 지배자 신고서 작성유무
  isWriteOwner: false,
  // 실질적 지배자 신고서 작성 일시
  corpOwnerTime: ''
};

const state = { ...mainState };

const JoinState = (set, get) => ({
  ...state,
  setSelectedButtonIndex: (index) => set({ selectedButtonIndex: index }),
  setPage(value) {
    set((state) => {
      return {
        pageType: {
          ...state.pageType,
          select: value
        }
      };
    });
  },
  setUserCate(value) {
    set((state) => {
      return {
        joinInputData: {
          ...state.joinInputData,
          userCate: {
            ...state.joinInputData.userCate,
            select: value
          }
        }
      };
    });
  },
  setUserInfo(value) {
    set((state) => {
      return {
        joinInputData: {
          ...state.joinInputData,
          userData: {
            ...state.joinInputData.userData,
            ...value
          }
        }
      };
    });
  },
  setUserData(key, value) {
    set((state) => {
      return {
        joinInputData: {
          ...state.joinInputData,
          userData: {
            ...state.joinInputData.userData,
            [key]: value
          }
        }
      };
    });
  },
  setInvalidFocus(value) {
    set((state) => {
      return {
        ...state,
        joinInputData: {
          ...state.joinInputData,
          invalidFocus: value
        }
      };
    });
  },
  setCountryList(value) {
    set(() => ({
      countryList: value
    }));
  },
  setKycPage(value) {
    set((state) => {
      return {
        kycPageType: {
          ...state.kycPageType,
          select: value
        }
      };
    });
  },
  setIdCard(key, value) {
    set((state) => {
      return {
        joinInputData: {
          ...state.joinInputData,
          userData: {
            ...state.joinInputData.userData,
            idcardImg: {
              ...state.joinInputData.userData.idcardImg,
              [key]: value
            }
          }
        }
      };
    });
  },
  setSignPw(key, value) {
    set((state) => {
      return {
        signPw: {
          ...state.signPw,
          [key]: value
        }
      };
    });
  },
  setSignImg(key, value) {
    set((state) => {
      return {
        signImg: {
          ...state.signImg,
          [key]: value
        }
      };
    });
  },
  setModalControl(key, value, modalType, addrType) {
    set((state) => {
      return {
        [modalType]: {
          ...state[modalType],
          [key]: value,
          type: addrType
        }
      };
    });
  },
  setAlert(type, value, title, body) {
    set((state) => {
      return {
        alertModal: {
          ...state.alertModal,
          state: type,
          show: value,
          title: title,
          body: body
        }
      };
    });
  },
  setCommonData(key, value) {
    set(() => {
      return {
        [key]: value
      };
    });
  },
  init() {
    set((state) => {
      return {
        ...mainState,
        joinInputData: {
          ...this.joinInputData,
          userData: { ...userDefaultData }
        },
        signPw: {
          set: '',
          confirm: ''
        }
      };
    });
  }
});

const JoinStore = create(createStore(JoinState));

export default JoinStore;
