<template>
  <BaseModal
    @onClose="MainStore.setModalControl('show', false, 'pwModal')"
    :btnText="messages.common.modal.close[language]"
    :width="400"
    :padding="CommonStore.isMobile ? '2.4rem 0' : '32px 0'"
  >
    <template v-slot:modalContent>
      <div :class="tStyle.modalWrap">
        <h3 :class="tStyle.title">{{ message.myPage.auth.title[language] }}</h3>
        <p :class="tStyle.title2">{{ message.myPage.auth.title2[language] }}</p>
        <div class="relative flex items-center mt-[32px]">
          <input
            :type="pwView ? 'text' : 'password'"
            :placeholder="message.myPage.auth.pwInput[language]"
            :class="tStyle.input"
            v-model="MyPageStore.userPw"
          />
          <img
            :src="require(`@/assets/${pwView ? 'invisible' : 'visible'}.png`)"
            alt=""
            class="absolute w-8 cursor-pointer right-4 opacity-70"
            @click="pwView = !pwView"
          />
        </div>
      </div>
    </template>
    <template v-slot:addBtn>
      <button :class="tStyle.addrBtn" @click="goMyPage">
        {{ messages.myPage.auth.move[language] }}
      </button>
    </template>
  </BaseModal>
</template>
<script setup>
import { reactive, ref, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import BaseModal from '@/feature/common/components/BaseModal.vue';
import message from '@/lib/message.json';

import { rootStore } from '@/store/rootStore';

import Request from '@/utils/api/Request';
import ShaPassword from '@/utils/api/ShaPassword';
import getLog from '@/utils/getLog';

const MainStore = reactive(rootStore?.mainStore());
const LoginStore = reactive(rootStore?.loginStore());
const CommonStore = reactive(rootStore?.commonStore());
const MyPageStore = reactive(rootStore?.myPageStore());

const language = ref(CommonStore.language);

const messages = ref(message);

const router = useRouter();

const pwView = ref(false);

const goMyPage = async () => {
  const reqData = {
    userPw: ShaPassword(MyPageStore.userPw)
  };

  let result = await Request(
    '/owuser/api/mypage/v1/login_mypage',
    'post',
    reqData
  );

  if (!result) return;
  getLog('mypage chkpw api', result);

  const { API_CODE } = result;

  if (API_CODE.status === '0000') {
    router.push('/mypage');
    MainStore.pwModal.show = false;
    MainStore.setSideMenu('show', false);
  }
};

onUnmounted(() => {
  MyPageStore.userPw = '';
});

const tStyle = ref({
  modalWrap: 'text-left px-[24px] pc:pb-[32px] mobile:pb-[2.4rem] text-center',
  title: 'text-[20px] font-semibold tracking-[-0.48px]',
  title2:
    'text-[#646464] text-[20px] font-medium tracking-[-0.4px] mt-[16px] mobile:text-[1.666rem]',
  input:
    'px-[16px] pt-[15px] pb-[14px]  rounded-[4px] border border-[#CFCFCF] w-full mobile:text-[1.4rem] placeholder:text-[#999]',
  addrBtn:
    'text-[#fff] rounded-[4px] bg-key py-[9px] w-[132px] disabled:bg-dis mobile:basis-1/2'
});
</script>
