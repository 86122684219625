import { createStore } from 'zustand/vanilla';
import create from 'vue-zustand';

const defaultBankInfo = {
  bkName: '',
  bkArea: '',
  bkCode: '',
  bkType: '',
  bkOwner: '',
  bkNum: '',
  sendReason: ''
};

const defaultState = {
  // 현재 마감/갱신 신청 상태인지 체크
  // ** 다이렉트 접근 방지
  isOverDo: false,
  // 중도해약할 기금 시퀀스 정보
  overSeq: {
    fundSeq: '',
    fundUserSeq: ''
  },
  fundInfo: {
    fundName: '',
    receiptNo: '',
    quantity: '',
    fundAmt: '',
    fundBkType: '',
    unitPrice: '',
    unitPriceType: '',
    addCash: '',
    addCashAmt: '0',
    minusCash: '',
    minusCashAmt: '0',
    minusCash: '',
    minusCashAmt: '',
    returnType: '',
    returnBank: '',
    receiptYn: '',
    keepType: '',
    reChkPaper: 'N',
    inputDate: '',
    accountMultiply: ''
  },
  increase: {
    qty: '',
    date: ''
  },
  decrease: {
    qty: '',
    returnType: '',
    returnBank: ''
  },
  transInfo: {
    lname: '',
    fname: '',
    furiLname: '',
    furiFname: '',
    zipCode: '',
    addr: ''
  },
  currentBankInfo: {},
  getUserBankInfo: {},
  inputBankInfo: defaultBankInfo
};

const state = { ...defaultState };

const OverState = (set, get) => ({
  ...state,
  setCommonData(key, value) {
    set((state) => {
      return {
        ...state,
        [key]: value
      };
    });
  },
  resetState() {
    set((state) => {
      return {
        ...defaultState,
        inputBankInfo: {
          bkName: '',
          bkArea: '',
          bkCode: '',
          bkType: '',
          bkOwner: '',
          bkNum: '',
          sendReason: ''
        }
      };
    });
  }
});

const OverStore = create(createStore(OverState));

export default OverStore;
