import { createStore } from 'zustand/vanilla';
import create from 'vue-zustand';

const defaultState = {
  // fundingList: [],
  fundingPage: {},
  fundendList: [],
  fundendPage: {},
  cancelModal: {
    show: false
  },
  modalState: '',
  currFund: {},
  fundingList: [],
  repage: false
};

const state = { ...defaultState };

const FundState = (set, get) => ({
  ...state,
  setCommonData(key, value) {
    set(() => {
      return {
        [key]: value
      };
    });
  },
  setModalControl(key, value, modalType, data) {
    set((state) => {
      return {
        [modalType]: {
          ...state[modalType],
          [key]: value,
          ...data
        }
      };
    });
  },
  resetModal() {
    set((state) => {
      return {
        ...state,
        modalState: '',
        currFund: {}
      };
    });
  },
  resetState() {
    set((state) => {
      return {
        ...defaultState
      };
    });
  }
});

const FundStore = create(createStore(FundState));

export default FundStore;
