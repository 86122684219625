import { createStore } from 'zustand/vanilla';
import create from 'vue-zustand';

const defaultBankInfo = {
  bkName: '',
  bkArea: '',
  bkCode: '',
  bkType: '',
  bkOwner: '',
  bkNum: '',
  sendReason: ''
};

const defaultState = {
  // 현재 중도해약 신청 상태인지 체크
  // ** 다이렉트 접근 방지
  isStopDo: false,
  // 중도해약할 기금 시퀀스 정보
  stopSeq: {
    fundSeq: '',
    fundUserSeq: ''
  },
  fundInfo: {
    fundName: '',
    receiptNo: '',
    quantity: '',
    fundAmt: '',
    fundBkType: '',
    unitPrice: 0,
    unitPriceType: '',
    returnType: '',
    returnBank: '',
    receiptYn: ''
  },
  transInfo: {
    furiLname: '',
    furiFname: '',
    lname: '',
    fname: '',
    zipCode: '',
    addr: ''
  },
  currentBankInfo: {},
  getUserBankInfo: defaultBankInfo,
  inputBankInfo: defaultBankInfo
};

const state = { ...defaultState };

const StopState = (set, get) => ({
  ...state,
  setCommonData(key, value) {
    set((state) => {
      return {
        ...state,
        [key]: value
      };
    });
  },
  resetState() {
    set((state) => {
      return {
        ...defaultState,
        inputBankInfo: {
          bkName: '',
          bkArea: '',
          bkCode: '',
          bkType: '',
          bkOwner: '',
          bkNum: '',
          sendReason: ''
        }
      };
    });
  }
});

const StopStore = create(createStore(StopState));

export default StopStore;
