import { createStore } from 'zustand/vanilla';
import create from 'vue-zustand';
import message from '@/lib/message.json';

const joinUserDefault = [
  {
    idx: '1',
    lname: '',
    fname: '',
    rank: '',
    desc: ''
  },
  {
    idx: '2',
    lname: '',
    fname: '',
    rank: '',
    desc: ''
  },
  {
    idx: '3',
    lname: '',
    fname: '',
    rank: '',
    desc: ''
  },
  {
    idx: '4',
    lname: '',
    fname: '',
    rank: '',
    desc: ''
  },
  {
    idx: '5',
    lname: '',
    fname: '',
    rank: '',
    desc: ''
  },
  {
    idx: '6',
    lname: '',
    fname: '',
    rank: '',
    desc: ''
  },
  {
    idx: '7',
    lname: '',
    fname: '',
    rank: '',
    desc: ''
  },
  {
    idx: '8',
    lname: '',
    fname: '',
    rank: '',
    desc: ''
  },
  {
    idx: '9',
    lname: '',
    fname: '',
    rank: '',
    desc: ''
  },
  {
    idx: '10',
    lname: '',
    fname: '',
    rank: '',
    desc: ''
  }
];

const state = {
  // modal type
  // 참여자 = attend , 앙케이트 = qs , 보고서 = report , 앙케이트제출 = inq
  modalState: '',
  // 현재 선택한 이벤트의 코드
  currEvent: {},
  defaultReportUser: {
    idx: '',
    lname: '',
    fname: '',
    rank: '',
    desc: ''
  },
  attendModal: {
    show: false
  },
  questionModal: {
    show: false
  },
  reportModal: {
    show: false
  },
  inquiryModal: {
    show: false
  },
  filter1List: [
    {
      state: '1',
      msg: message.event.state.due
    },
    {
      state: '2',
      msg: message.event.state.start
    },
    {
      state: '3',
      msg: message.event.state.cancel
    },
    {
      state: '4',
      msg: message.event.state.end
    }
  ],
  filter2List: [
    {
      state: '1',
      msg: message.event.eventName
    },
    {
      state: '2',
      msg: message.event.divide
    },
    {
      state: '3',
      msg: message.event.placeName
    }
  ],
  joinEventSett: {
    type: '2',
    filter1: '0',
    filter2: '',
    keyword: '',
    viewCnt: '3',
    pageNo: '1'
  },
  joinEventList: [],
  attendList: [
    {
      name: '대표자성대표자이름',
      rank: 'BM',
      state: 'Y',
      userType: '1',
      attendSeq: '26b2294b-fbc5-4f55-8d76-9f44b8a65dc1'
    },
    {
      name: '비회원성비회원이름',
      rank: '비회원',
      state: 'N',
      userType: '2',
      attendSeq: '20'
    }
  ],
  joinUserList: joinUserDefault,
  reportData: {
    content: '',
    report: '',
    signNum: ''
  }
};

const EventState = (set, get) => ({
  ...state,
  setModalControl(key, value, modalType, data) {
    set((state) => {
      return {
        [modalType]: {
          ...state[modalType],
          [key]: value,
          ...data
        }
      };
    });
  },
  setCommonData(key, value) {
    set((state) => {
      return {
        ...state,
        [key]: value
      };
    });
  },
  setReportData(value) {
    set((state) => {
      return {
        ...state,
        reportData: {
          ...state.reportData,
          value
        }
      };
    });
  },
  resetModal() {
    set((state) => {
      return {
        ...state,
        modalState: '',
        currEvent: {},
        reportData: {
          content: '',
          report: '',
          signNum: ''
        }
      };
    });
  }
});

const EventStore = create(createStore(EventState));

export default EventStore;
